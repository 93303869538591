const colorConstants = {
    BrandColors: {
        INDIGO: "#221F54",
        ROYAL_PURPLE: "#4E30A1",
        ULTRAVIOLET: "#7259EF",
        PLUM: "#535383",
        BRASS: "#A88F4D",
        COOL_GRAY: "#D5DCEF",
    },
    // colors used in many visualizations (mentions graphs, all 3 sheets graphs), copied from DESIGN.styl
    QUORUM_BLUE: "#503ABD",
    QUORUM_GREY_1: "#ecf0f1",
    QUORUM_GREY_2: "#b4bcc2",
    QUORUM_GREY_3: "#95a5a6",
    QUORUM_GREY_4: "#7b8a8b",
    QUORUM_GREY_5: "#ccc",
    BACKGROUND_LIGHT_BLUE: "#e7eaf3",
    HYPER_LINK_PURPLE: "#503ABD",
    MIDDLE_PURPLE: "#5f2b7b",
    DARK_PURPLE: "#3D3262",
    PARTY_COLORS: {
        DEMOCRAT: "#1f77b4",
        REPUBLICAN: "#d62728",
        INDEPENDENT: "#8c564b",
        OTHER: "#B4BCC2",
    },
    LightBorderColor: "#ced5e7",
    WHITE: "#ffffff",
    GRAPH_BLUES_COLOR_RANGE: ["#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"],
    FacebookBlue: "#3B5998",
    TwitterLightBlue: "#00aced",
    TwitterBlue: "#4099FF",
    TwitterDarkBlue: "#0084B4",
    TwitterDarkestBlue: "#0079A5",
    LinkedInBlue: "#0177b5",
    GooglePlusOrange: "#dd5044",
    PinterestRed: "#d8121d",
    MediumGreen: "#02b875",
    YoutubeRed: "#cc181e",
    InstagramPurpleViolet: "#8a3ab9",
    InstagramBlue: "#4c68d7",
    InstagramMaroon: "#cd486b",
    InstagramOrange: "#fbad50",
    InstagramYellow: "#fccc63",
    InstagramRedViolet: "#bc2a8d",
    InstagramRedOrange: "#e95950",
    VoteYea: "#108102",
    VoteNay: "#D72729",
}

const STATES_IN_SESSION_NAME = "States in Session"
const STATES_OUTTA_SESSION_NAME = "States out of Session"
const OFFICIAL_ISSUE_STAT_FIELD = "bill_terms"
const SESSION_STAT_FIELD = "in_sessions"

module.exports = {
    ...colorConstants,
    TASTYPIE_QUERY_LIMIT: 20,
    DEFAULT_DOWNLOADS_GET_LIMIT: 100,
    DEFAULT_ALERT_GROUP_TITLE: "Quorum Update",
    CURRENT_YEAR: new Date().getFullYear(),
    ADVANCED_SEARCH_ID: "advancedSearchFilter",
    SEARCH_PILLS: 6,
    ENTERKEY: 13,
    ESCAPEKEY: 27,
    UPKEY: 38,
    DOWNKEY: 40,
    MAX_CSV_SIZE: 1000,
    MAX_EXCEL_SIZE: 1000,
    MAX_JOIN_SIZE: 1000,
    MAX_WORD_SIZE: 100,
    SEARCH_SCROLL_THRESHOLD: 0.5,
    SEARCH_SCROLL_PUBLISH_INTERVAL: 150,
    LIST_ITEM_INTERVAL: 30,
    LIST_SCROLL_THRESHOLD: 0.65,
    LIST_ITEM_HEIGHT: 60,
    LIST_WIDTH: 400,
    UNMAGIC_LIST_MAX: 2000,
    ES_UNMAGIC_LIST_MAX: 65000,
    NUMBER_OF_LISTS_INITIALLY_SHOWN_ON_BOARD: 6,
    NUM_ITEMS_PER_STAT_LIST: 15,
    STAT_LIST_ITEM_HEIGHT: 45,
    STAT_LIST_HEIGHT: 405,
    STAT_LIST_TOTAL_HEIGHT: 455,
    STATE_ALL_PERSON_ENDPOINT: "/historical.json",
    CURRENT_SESSION: 115,
    TERM: 7659,
    ALL_SESSIONS: "all",
    STAFFER_IMG_URL: "/static/img/seal.png",
    EU_BILL_STATUS_BOUNDARY: 100,
    // react bootstrap
    COL_12_CONFIG: { xs: 12, sm: 12, md: 12, lg: 12 },
    COL_6_CONFIG: { xs: 6, sm: 6, md: 6, lg: 6 },
    BOOTSTRAP_COLS: 12,
    YEAR_TO_START_BILL_TRACKING_PROJECTS: new Date().getFullYear(),
    MOST_RECENT_CENSUS_YEAR: 2014,
    MAX_NUM_SEARCH_RESULTS_SEARCH_VIS_SHORT_INTERVAL: 20000,
    MAX_NUM_SEARCH_RESULTS_SHOW_WARNING: 100000,
    USER_ANALYTICS_MAX_DESCRIPTION_LENGTH: 250,
    DISCONNECTED_TEXT: "Please connect to the internet to continue using Quorum.",
    MEMBER_PROFILE_STATBOX_ORDER: [
        "num_sponsored_bill",
        "sponsored_num_left_committee_bill",
        "sponsored_num_enacted_bill",
        "percent_votes_with_party",
        "percent_cosponsored_bills_opp_sponsor",
        "avg_cosponsors_per_sponsored_bill",
    ],
    SCALAR_PILL_DATA: [
        "name",
        "preferred_name",
        "address",
        "city",
        "county_name",
        "email",
        "phone_number",
        "title",
        "unique_identifier",
    ],
    LINK_PILL_DATA: ["facebook_url_array", "instagram_array", "medium_array", "twitter_array", "youtube_array"],
    PILL_DATA_ASSOCIATED_ICONS: {
        facebook_url_array: "fa-facebook",
        instagram_array: "fa-instagram",
        medium_array: "fa-medium",
        twitter_array: "fa-twitter",
        youtube_array: "fa-youtube",
    },
    PILL_DATA_BASIC_NAME: {
        name: "Name",
        preferred_name: "Preferred Name",
        abbrev: "Abbreviation",
        email: "Email",
        address: "Address",
        input_address: "Address",
        alternate_address: "Alternate Address",
        shipping_address: "Address",
        title: "Title",
        facebook_url_array: "Facebook",
        public_organization_name: "Organization",
        formatted_phone: "Phone Number",
        formatted_primary_phone: "Home/Work Number",
        formatted_secondary_phone: "Cell Number",
        website: "Website",
        extension: "Telephone Extension",
        is_grassroots_supporter: "Grassroots Supporter",
        is_stakeholder_supporter: "Stakeholder Supporter",
        unsubscribed_from_bulk_emails: "Unsubscribed from Outbox",
        created: "Created",
        parent_organization_name: "Parent Organization",
        subject: "Event Title",
        location: "Location",
        description: "Description",
        when: "Start",
        end: "End",
        isCustomAllDayEvent: "All Day Event?",
        pointPersonName: "Point Person",
        updated: "Last Updated",
        last_user: "Last Updated By",
        valid_email: "Valid Email",
        unique_identifier: "Unique ID",
        source_type: "Original Source",
    },
    EXECUTIVE_PROFILE_STATBOX_ORDER: [
        "num_passed_bills",
        "num_vetoed_bills",
        "num_overridden_bills",
        "percent_vetoed_bills",
        "percent_vetoed_bills_opp_party",
        "percent_vetoed_bills_own_party",
    ],
    MAP_TRANSACTION_SUMMARY_KEY_TO_LABEL: {
        available_amount: "Available Balance",
        limit: "Limit",
        total_amount: "Total Amount",
        year: "Year",
        election: "Election",
    },
    TRANSACTION_SUMMARY_KEY_ORDER: {
        year: 0,
        total_amount: 1,
        limit: 2,
        available_amount: 3,
        election: 4,
    },
    EMAIL_DOWNLOAD_SWAL_JSON: {
        title: "Name your PDF Download",
        text: "This file is too large to download immediately. Instead, we'll send you an email when it is ready.",
        content: {
            element: "input",
            attributes: {
                placeholder: "Name your download...",
            },
        },
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Download",
                closeModal: false,
            },
        },
    },
    EMAIL_DOWNLOAD_SWAL_CSV: {
        title: "Name your CSV Download",
        text: "This file is too large to download immediately. Instead, we'll send you an email when it is ready.",
        content: {
            element: "input",
            attributes: {
                placeholder: "Name your download...",
            },
        },
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Download",
                closeModal: false,
            },
        },
    },
    EMAIL_DOWNLOAD_SWAL_CSV_POST_ERROR: {
        title: "Name your CSV Download",
        text: "We'll send you an email when it is ready.",
        content: {
            element: "input",
            attributes: {
                placeholder: "Name your download...",
            },
        },
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Download",
                closeModal: false,
            },
        },
    },
    EMAIL_DOWNLOAD_ERROR_WORKFLOW: {
        text: `There was an error downloading your file. Click below to send the file via email instead.`,
        icon: "error",
        title: "We're sorry!",
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Send by Email",
                closeModal: false,
                value: true,
            },
        },
    },
    EMAIL_DOWNLOAD_SWAL_CSV_CONFIRM: {
        icon: "info",
        title: "Email Pending",
        text: "Check your inbox in the next few minutes for a link to your download.",
    },
    CONTACT_TYPE_LABEL: "contact_type",
    CONTACT_TYPES: {
        only_grassroots: 1,
        only_stakeholder: 2,
        grassroots_and_stakeholder: 3,
        neither: 4,
    },
    CONTACT_INPUT_ADDRESS_FIELD: "input_address",
    CONTACT_ADDRESS_FIELD: "address",
    // Constants for social media
    SOCIAL_MEDIA_ARRAY: ["facebook_post", "tweet"],
    BASIC_FIELD_TOOLTIP_BLURB_ORGANIZATION:
        "Choose the name of this organization. If this organization is part of another organization (e.g. a subsidiary or department) you can also assign this organization to be part of another one.",
    SOCIAL_SECTION_BLURB:
        "You can enter any <b>public</b> Twitter, Facebook, Medium, Instagram, or Youtube account to this contact for us to track. We'll immediately start adding any new social media posts to our database and process any historical posts within 48 hours. You'll be able to see these posts through Document search and on this contact's profile.",
    CUSTOM_FIELD_TOOLTIP_BLURB:
        'A custom field is an attribute about a contact that your organization would like to keep track of - for example, "Is part of our Ambassador Program". You can set tags for already created custom fields, or you can click the "Manage Custom Fields" button to create a new custom field and the corresponding tags.',
    CUSTOM_FIELD_TOOLTIP_NO_ACCESS_BLURB:
        'A custom field is an attribute about a contact that your organization would like to keep track of - for example, "Is part of our Ambassador Program". You can set tags for already created custom fields below. To create a new custom field and the corresponding tags please contact your organization\'s administrator to grant you permission to access the custom fields management page.',
    ORG_CUSTOM_FIELD_TOOLTIP_BLURB:
        'A custom field is an attribute about an organization that you would like to keep track of - for example, "Organization Type". You can set tags for already created custom fields, or you can click the "Manage Custom Fields" button to create a new custom field and the corresponding tags.',
    ORG_CUSTOM_FIELD_TOOLTIP_NO_ACCESS_BLURB:
        'A custom field is an attribute about an organization that you would like to keep track of - for example, "Organization Type". You can set tags for already created custom fields below. To create a new custom field and the corresponding tags please contact your organization\'s administrator to grant you permission to access the custom fields management page.',
    CUSTOM_FIELD_NONE_CREATED_BLURB:
        'Your organization does not have any custom fields created. Click "Manage Custom Fields" to create a new custom field.',
    CUSTOM_FIELD_NONE_CREATED_BLURB_PREFIX: "Your organization does not have any custom fields created for ",
    CUSTOM_FIELD_NONE_CREATED_BLURB_SUFFIX: '. Click "Manage Custom Fields" to create a new custom field.',
    // Assignments form section
    ASSIGNMENT_SECTION_CONTACT_BLURB:
        "Assign this contact to one or more team members. By default, new contacts are assigned to you.",
    ASSIGNMENT_SECTION_PUBORG_BLURB:
        "Assign this organization to one or more team members. By default, new organizations are assigned to you.",
    ASSIGNMENT_SECTION_USER_TOOLTIP: "Choose one or more team members to assign to this entity.",
    ASSIGNMENT_SECTION_SUPPORTER_TOOLTIP: "Choose one or more contacts to assign to this entity.",
    SET_ASSIGNMENT_PRIMARY: "Mark as primary assignment.",
    UNSET_ASSIGNMENT_PRIMARY: "Unmark as primary assignment.",
    // Official custom fields
    CUSTOM_FIELD_OFFICIAL_FORM_BLURB:
        'A custom field is an attribute about an official that your organization would like to keep track of - for example, "Position on Space Exploration". You can set tags for already created custom fields, or you can click the "Manage Custom Fields" button to create a new custom field and the corresponding tags.',
    CUSTOM_FIELD_OFFICIAL_FORM_NO_ACCESS_BLURB:
        'A custom field is an attribute about an official that your organization would like to keep track of - for example, "Position on Space Exploration". You can set tags for already created custom fields below. To create a new custom field and the corresponding tags please contact your organization\'s administrator to grant you permission to access the custom fields management page.',
    CUSTOM_FIELD_OFFICIAL_NONE_CREATED_BLURB:
        'Your organization does not have any custom fields created for officials and staff. Click "Manage Custom Fields" to create a new custom field.',
    // Bill custom fields
    CUSTOM_FIELD_BILL_FORM_BLURB:
        'A custom field is an attribute about a bill that your organization would like to keep track of - for example, the financial impact this bill would have on your organization. You can set tags for already created custom fields, or you can click the "Manage Custom Fields" button to create a new custom field and the corresponding tags.',
    CUSTOM_FIELD_BILL_FORM_NO_ACCESS_BLURB:
        "A custom field is an attribute about a bill that your organization would like to keep track of - for example, the financial impact this bill would have on your organization. You can set tags for already created custom fields below. To create a new custom field and the corresponding tags please contact your organization's administrator to grant you permission to access the custom fields management page.",
    CUSTOM_FIELD_BILL_CREATED_BLURB:
        'Your organization does not have any custom fields created for bills. Click "Manage Custom Fields" to create a new custom field.',
    // Committee custom fields
    CUSTOM_FIELD_COMMITTEE_FORM_BLURB:
        'A custom field is an attribute about a committee that your organization would like to keep track of - for example, whether it is a priority committee. You can set tags for already created custom fields, or you can click the "Manage Custom Fields" button to create a new custom field and the corresponding tags.',
    CUSTOM_FIELD_COMMITTEE_FORM_NO_ACCESS_BLURB:
        "A custom field is an attribute about a committee that your organization would like to keep track of - for example, whether it is a priority committee. You can set tags for already created custom fields below. To create a new custom field and the corresponding tags please contact your organization's administrator to grant you permission to access the custom fields management page.",
    CUSTOM_FIELD_COMMITTEE_CREATED_BLURB:
        'Your organization does not have any custom fields created for committees. Click "Manage Custom Fields" to create a new custom field.',
    // custom contacts bulk upload
    UPLOAD_CSV_FOR_BULKUPLOAD_BLURB:
        "Upload your CSV, XLS, or XLSX file here. For the fastest and most reliable upload, export your spreadsheet as a CSV before uploading. Download the example template to guide your csv's format. Each column in the first row should have a unique header and all subsequent rows should contain your data.",
    MATCH_FIELDS_FOR_BULKUPLOAD_BLURB:
        "You can match your column headers to Quorum fields here. Click a Quorum field in a category on the right to match it to the selected field highlighted in purple. If you don't want to match this field, click 'Skip To Next Field'.",
    CUSTOM_FIELD_FOR_BULKUPLOAD_BLURB:
        'A custom field is an attribute about a contact, person, bill, etc. that your organization would like to keep track of - for example, "Is part of our Ambassador Program". You can click the "Manage Custom Fields" button to create a new custom field and the corresponding tags.',
    CUSTOM_FIELD_FOR_BULKUPLOAD_NO_ACCESS_BLURB:
        'A custom field is an attribute about a contact, person, bill, etc. that your organization would like to keep track of - for example, "Is part of our Ambassador Program". To create a new custom field and the corresponding tags please contact your organization\'s administrator to grant you permission to access the custom fields management page.',
    SUBMIT_FORM_FOR_BULKUPLOAD_BLURB:
        "Submit to finish your bulk upload. It may take up to 24 hours for the data to be added.",
    //
    CONTACT_TYPE_BLURB:
        "Define this individual's relationship to your organization. A Grassroots Contact is an individual who would advocate on your behalf. A Stakeholder Contact is an influencer for your organization's public policy objectives. A contact can be both or neither.",
    DEFAULT_CUSTOM_DESCRIPTION_TEXT: "Click the button to the right to create a custom description...",
    DESIGN_SYSTEM_DEFAULT_CUSTOM_DESCRIPTION_TEXT: "Add special insight for your organization...",
    PAC_ORG_TYPE_DESCRIPTION_TEXT: "PAC type can be used in sorting and filtering through search and sheets.",
    PARENT_ORGANIZATION_DESCRIPTION_TEXT:
        "If the organization you are adding is part of another organization (e.g. a department within a larger company) you can link the two organizations together by adding the larger organization as this organization's parent.",
    DEFAULT_SELECTION_DESCRIPTION_TEXT:
        "You are currently editing your default design, which controls your campaign page and other default features on the site.",
    TWO_FACTOR_DESCRIPTION_TEXT:
        "Two-factor authentication provides an additional level of security for your account and helps to ensure that no one will be able to access it without your permission. Once you turn on two-factor authentication, upon logging in you will need to enter a code available from the Google Authenticator app for additional security.",
    SECURE_UPLOAD_DESCRIPTION_TEXT:
        "We take your security seriously and want you to have the ability to send us documents without using email. Files uploaded via this form will be encrypted and sent directly to our secure servers.",
    TEAM_MANAGEMENT_DESCRIPTION_TEXT:
        "A manager which allows you to control team membership on a person-by-person basis.",
    TEAM_MANAGEMENT_SHORTCUTS: "These allow you to quickly perform bulk actions for team membership.",
    CUSTOM_FILTER_HANDLING: [
        "custom_person_single_option_list_tag",
        "custom_person_multi_options_list_tag",
        "custom_person_bool_tag",
        "custom_person_string_tag",
        "custom_person_number_tag",
        "custom_person_number_tag_less",
        "custom_person_number_tag_greater",
        "custom_bill_single_option_list_tag",
        "custom_bill_multi_options_list_tag",
        "custom_bill_bool_tag",
        "custom_bill_string_tag",
        "custom_bill_number_tag",
        "custom_bill_number_tag_less",
        "custom_bill_number_tag_greater",
        "custom_supporter_single_option_list_tag",
        "custom_supporter_multi_options_list_tag",
        "custom_supporter_bool_tag",
        "custom_supporter_string_tag",
        "custom_supporter_number_tag",
        "custom_supporter_number_tag_less",
        "custom_supporter_number_tag_greater",
        "custom_organization_single_option_list_tag",
        "custom_organization_multi_options_list_tag",
        "custom_organization_bool_tag",
        "custom_organization_string_tag",
        "custom_organization_number_tag",
        "custom_organization_number_tag_less",
        "custom_organization_number_tag_greater",
    ],
    NO_RESULTS_ERROR: "An error occurred when conducting your search.",
    NO_RESULTS_EXPLANATION:
        "This combination of filters is not returning any results from our database. This is often caused by treating filters as being additive instead of cumulative. Every selected filter condition gets applied on top of all previous ones, and each item returned from those filters must meet all conditions.",
    NO_RESULTS_DEFAULT_FILTERS_EXPLANATION:
        "The default filters that you have selected are not returning any results from our database. Consider changing your default filters in Quorum's account management page!",
    NO_RESULTS_NO_FILTERS_EXPLANATION:
        "Nothing to see here! You need to add some data to see results from this search, or you might try changing your region.",
    MAX_LENGTH_QUERYSET_VIZ_CAP: 50000,
    MAX_LENGTH_QUERYSET_VIZ_CAP_ES_DOCS: 100000000,
    LINK_TAG: "<link",
    AWS_URL_CONTAINS_STRING: "amazonaws",
    S3_AWS_URL: "s3.amazonaws.com",
    MEDIA_PATH: "https://quorum-media.s3.amazonaws.com",
    STATIC_PATH: "https://static.quorum.us/global/",
    BLANK_AVATAR_PATH: "common/images/member-images/large/blank-avatar.jpg",
    FEDERAL_SEAL_AVATAR_PATH: "common/images/seals/Federal.png",
    SEAL_AVATAR_PATH: "common/images/seals/",
    NUM_HOURS_IN_DAY: 24,
    NUM_DAYS_IN_WEEK: 7,
    NUM_DAYS_IN_MONTH: 31,
    NUM_DAYS_IN_QUARTER: 90,
    NUM_DAYS_IN_YEAR: 365,
    DAY_STRING: "day",
    WEEK_STRING: "week",
    MONTH_STRING: "month",
    YEAR_STRING: "year",
    // currently used in ProjectProfile statistics to show that no scores have been entered
    NULL_STRING: "N/A",
    PROJECT_TIER_TOOLTIP_TEXT:
        "The tier is calculated based on the average of the organization scores that are available. This average is then compared with your organization's upper and lower tier bounds. Tier I are your high-priority issues, Tier III are your low-priority issues.",
    // color scale is used in ProjectProfile statistics to show severity of statistic
    STAT_COLOR_SCALE_START: "#A7B2CC",
    STAT_COLOR_SCALE_END: "#503ABD",

    SEARCHIFY_HELP_TEXT: `You can use this page to select either individual items or use the filters on the side to select a group of items.

To select or deselect items one at a time, click on each individually. You can use the filters or the searchbar to find particular items before clicking on them. When done, click the Submit Items button at the bottom of the page.

You can also use this tool to select groups of items. To do so, use the filters or searchbar to choose a selection. Then, either choose "Submit All" at the bottom to finish your selection or "Select All" to select a group of items and continue adding more. If you'd like to remove something from your selection after clicking "Select All" you can click on it to deselect it.

Want some help? Click on this button to contact Support.`,
    SEARCHIFY_PROMPT_TEXT: "Click to choose options using Power Search",
    CONTACT_UNSUBSCRIBE_TOOLTIP_TEXT:
        "You can use this setting to control whether a Contact is subscribed to any of your Outbox Emails. Please consider all relevant regulations (including CAN-SPAM and GDPR) if you choose to re-subscribe a Contact that has previously chosen to unsubscribe.",
    OFFICIAL_UNSUBSCRIBE_TOOLTIP_TEXT:
        "You can use this setting to control whether a Official is subscribed to any of your Outbox Emails. Please consider all relevant regulations (including CAN-SPAM and GDPR) if you choose to re-subscribe a Official that has previously chosen to unsubscribe.",
    PROFILE_HEADER_ICON_COLOR: "#4d2d5f",
    PROFILE_HEADER_BORDER_COLOR: "#95a5a6",
    SIMPLE_CSV_FORMAT: "simpleCSV",
    // graph enums
    GRAPH_SCALE_VARIABLES: {
        xAxis: "xAxis",
        yAxis: "yAxis",
        size: "size",
        color: "color",
    },
    GRAPH_TYPES: {
        Scatter: "Scatter",
        Histogram: "Histogram",
        Bar: "Bar",
    },
    // colors used in mentions graphs, copied from DESIGN.styl
    QUORUM_BLUE: "#503ABD",
    MIDDLE_PURPLE: "#5f2b7b",
    DARK_PURPLE: "#3D3262",
    MAX_SIDEBAR_OPTIONS: 10,
    MIN_PASSWORD_STRENGTH_SCORE: 2,

    // mentions graphs blurbs
    SELECTED_BAR_TEXT: 'Click "Apply Filters" to change results below...',
    MENTIONS_APPLY_FILTERS_TOOLTIP:
        "Select filters by clicking the bars above. Click this button to apply selected filters highlighted in purple to the results below.",

    MINUTE_IN_MSEC: 60000,
    MAX_LOGIN_TIME_IN_MINUTES: 30,
    DOMAIN_NAME: "quorum.us/",
    LIVE_CHAT_LICENSE_ID: 6575521,
    MULTILINE_INPUT_HEIGHT: 160,
    SINGLE_LINE_INPUT_HEIGHT: 40,
    FORGOT_PASSWORD: "forgotPassword",
    FORGOT_PASSWORD_BUTTON_TEXT: "Forgot Password",
    PAGE_404_WORDCLOUD: "https://s3.amazonaws.com/quorum-static/static/img/404.png",

    // Redux Form keys
    ALERT_FORM_KEY: "alertForm",
    ALIGNMENT_TYPE_FORM_KEY: "alignmentTypeForm",
    BILL_FORM_KEY: "billForm",
    BULK_ACTIONS_FORM_KEY: "bulkActionsForm",
    CONTACT_FORM_KEY: "contactForm",
    CUSTOM_REGION_FORM_KEY: "customRegionForm",
    CUSTOM_COLUMN_COLOR_FORM_KEY: "customColumnColorForm",
    CUSTOM_INTERACTION_TYPE_FORM_KEY: "customInteractionTypeForm",
    CUSTOM_FIELD_GROUP_FORM_KEY: "customFieldGroupForm",
    DASHBOARD_FORM_KEY: "dashboardForm",
    DASHBOARD_EDIT_FORM_KEY: "dashboardEditForm",
    EVENT_FORM_KEY: "eventForm",
    GRASSROOTS_PAGE_FORM_KEY: "grassrootsPageForm",
    GRASSROOTS_REGISTRATION_PAGE_FORM_KEY: "grassrootsRegistrationPageForm",
    ISSUE_MANAGEMENT_FORM_KEY: "issueManagementForm",
    LOG_COMMENT_FORM_KEY: "logCommentForm",
    LOG_DONATION_FORM_KEY: "logDonationForm",
    LOG_INTERACTION_FORM_KEY: "logInteractionForm",
    LOG_RELATIONSHIP_FORM_KEY: "logRelationshipForm",
    OFFICIAL_FORM_KEY: "officialForm",
    PRESS_CONTACT_FORM_KEY: "pressContactForm",
    ORGANIZATION_FORM_KEY: "organizationForm",
    ORGANIZATION_CUSTOM_FIELDS_MOBILE_FORM_KEY: "organizationCustomFieldsForm",
    OUTBOUND_TEXTING_FORM_KEY: "outboundTextingComposeForm",
    REGISTRATION_FORM_KEY: "registrationForm",
    UNSUBSCRIBE_FORM_KEY: "unsubscribeForm",
    WIDGET_EDIT_FORM_KEY: "widgetEditForm",
    ACCOUNT_SECURITY_SETTINGS_FORM_KEY: "accountSecuritySettingsForm",
    ACCOUNT_INFORMATION_SETTINGS_FORM_KEY: "accountInformationSettingsForm",
    ACCOUNT_SEARCH_SETTINGS_FORM_KEY: "accountSearchSettingsForm",
    ACCOUNT_SIDEBAR_SETTINGS_FORM_KEY: "accountSidebarSettingsForm",
    ORG_ADMIN_FORM_KEY: "orgAdminForm",
    RECEIPT_FORM_KEY: "receiptForm",
    DISBURSEMENT_FORM_KEY: "disbursementForm",
    FINANCIALS_FORM_KEY: "financialsForm",
    PREPARE_FORM_ONE_FORM_KEY: "prepareFormOneForm",
    RECONCILIATION_NEW_FORM_KEY: "reconciliationNewForm",
    PAC_SETTINGS_FORM_KEY: "pacSettingsForm",
    PAC_FINANCIALS_SETTINGS_FORM_KEY: "pacSettingsForm",
    PAC_FORM1_SETTINGS_FORM_KEY: "pacForm1SettingsForm",
    GRASSROOTS_SUPPORTER_ACTION_FORM_KEY: "grassrootsSupporterActionForm",
    STANCE_SETTINGS_FORM_KEY: "stanceSettingsForm",

    // Custom region
    STATES_IN_SESSION_NAME,
    STATES_OUTTA_SESSION_NAME,
    SPECIAL_REGIONS: [STATES_IN_SESSION_NAME, STATES_OUTTA_SESSION_NAME],

    USER_RECIPIENT_KEY: "recipient",
    SUPPORTER_RECIPIENT_KEY: "recipient_supporter",

    // Leadership Connect Account
    LEADERSHIP_CONNECT_ORG_ID: 2327,

    // profiles-new
    BILL_FULL_TEXT_LABEL: "Full Text",
    BILL_OFFICIAL_DESCRIPTION: "Official Description",
    SPONSOR_LIMIT: 4,
    COMMITTEES_REQUEST_LIMIT: 500,
    // these are the constants used to store the selected values from the filter
    // in the store and for adding additional filters to the api call
    OFFICIAL_ISSUE_STAT_FIELD,
    SESSION_STAT_FIELD,
    REGION_STAT_FIELD: "region",
    CHAMBER_STAT_FIELD: "chamber",
    YEAR_STAT_FIELD: "years",
    DEFAULT_DOCUMENT_ICON: "fa-file-text-o",
    OUTBOX_TABLE_NO_RESULTS_MESSAGE: "Did you just send your e-mail? It may take 2-3 minutes for the receipts to load.",
    SNIPPET_SEARCH_KEY: "snippet_search_term",

    // Help Center Post IDs
    DEFAULT_TUTORIAL_POST_ID: 110, // /tutorial/
    DEFAULT_GLOSSARY_POST_ID: 118,
    DEFAULT_REGISTRATION_TUTORIAL_POST_ID: 110,
    DEFAULT_TUTORIAL_MODULE_ID: 24, // /tutorial/
    DEFAULT_REGISTRATION_TUTORIAL_MODULE_ID: 24,

    // the difference between Alabama State and Alabama Local
    // in DjangIO.app.models.Region
    LOCAL_REGION_OFFSET: 52,

    STATS_WITH_LISTS: [SESSION_STAT_FIELD, OFFICIAL_ISSUE_STAT_FIELD],
    ISSUE_STAT_EXPLAINER_TEXT:
        "The visualization below is the very first tool that was built at Quorum and was " +
        "originally based on Quorum's co-founder Jonathan's research into protein networks. Each dot represents a legislator, and the thickness of the" +
        "line between each pair of dots represents the number of pieces of legislation they have worked on together. You can use this graph to see how legislators work " +
        "together on a particular issue, identify which legislators are most central to a particular issue, or figure out how to expand your legislative coalition.",

    BILL_TITLE_TEXT_CUTOFF: 500,
    SCHEDULER_TYPES: {
        capitol: {
            value: "capitol",
            scheduler_filter: { is_capitol_office: true },
            selectionLabel: "Capitol",
        },
        district: {
            value: "district",
            scheduler_filter: { is_capitol_office: false },
            selectionLabel: "District",
        },
        both: {
            value: "both",
            scheduler_filter: {},
            selectionLabel: "All",
        },
    },
    AVAILABLE_TO_EVERYONE: 0,
    READ_LIMITED_TO_TEAMS: 1,
    READ_JUST_ME: 2,
    REGISTRATION_PERSONA_TEXT:
        "Which of Quorum's products are you interested in? We use this information " +
        "to customize your experience in Quorum and get your account set up properly.",
    teamPermissioningHelpText: (canPermissionToPartners, shouldRenderPartnerToggle) => {
        return (
            `You can choose to make this available to everyone at your` +
            ` organization or only for particular teams that you are part of, and ` +
            `manage your default settings in Accounts. ` +
            `${
                canPermissionToPartners && shouldRenderPartnerToggle
                    ? "You can also choose to share this with all of your partner organizations. "
                    : ""
            }` +
            `To learn more about Team Permissioning in Quorum, visit our Help Guide `
        )
    },
    DEFAULT_TEAM_PERMISSIONING_TEXT:
        "Default teams allow you to choose which teams " +
        "within your organization you would like everything you create to be assigned " +
        "to. For example, if you're on the Federal Affairs team, you might want " +
        "every Sheet you create to only show up for other members of your Federal " +
        "Affairs team and not show up for anyone on the State Affairs team. You " +
        "can always choose to increase or decrease the permissions of everything " +
        "you create when you're creating or editing a particular object.",

    PRIORITY_FILTER_KEY: "my_priority",
    STANCE_FILTER_KEY: "my_stance",

    SEGUE_TO_DOCUMENTS_TEXT: "See Documents",
    ORG_DEFAULT_EMAIL_LIMIT: 31250,

    ISSUES_BULK_EMAIL_FIELD: "emails",

    MEDIA_FLV: "ArticleDefaultSearch",
    MEDIA_TERMS_AND_CONDITIONS_FOOTER: `\
        This content is provided pursuant to the <a href="https://www.lexisnexis.com/en-us/terms/LACommercial/terms.page">LexisNexis General Terms and Conditions</a>.\
    `,
    OFFICIAL_FLV: "OfficialFilterableListViewDefaultSearch",
    STAFFER_FLV: "StafferFilterableListViewDefaultSearch",
    OLD_DEFAULT_QUORUM_DOMAIN: ".quorum.us",
    DEFAULT_QUORUM_DOMAIN: ".quorumoutbox.com",
    ATTRIBUTIONS_COLOR: "#3A3A3A",
    LC_ATTRIBUTIONS_TEXT: "Federal, state, and local contact information provided by Leadership Connect, Inc.",
    QUORUM_SCHOOL_URL: "https://school.quorum.us/",
    // Google Inc. and Google Testing Account IDs
    GOOGLE_ACCOUNT_IDS: [2991, 3285],
    DASHBOARD_EXTERNAL_REGION_TOOLTIP:
        "If you'd like the external dashboard to be limited to a particular region or group of regions, you can set that here. If you'd like to create a custom list of regions, you can do that by creating a new custom region at the top of left of the page within the region selector.",
}
